import billImage from '../assets/bill.jpg'

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={billImage} className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About the artist</h2>
              <p>{props.data ? props.data.about : "loading..."}</p>
              <h3>Artists statement</h3>
              <p>{props.data ? props.data.statement : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
