import atHomeMagazine from '../assets/at_home_magazine.jpg'
const styles = {
  img: {
    width: '80%',
    flex: 1
  },
  list: {
    width: '50%'
  }
}

export const Accolades = (props) => {
  return (
    <div id="accolades">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-5">
            {" "}
            <img src={atHomeMagazine} style={styles.img} className="img-responsive" alt="" />{" "}
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className='section-title'>
              {/* <div className="about-text"> */}
                <h2>Selected Corporate Collections</h2>
              </div>
            <div className="list-style">
              <div className="col-md-5" style={styles.list}>
                <ul>
                  {props.data
                    ? props.data.CorporateCollections.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                    : "loading"}
                </ul>
              </div>
              <div className="col-md-5" style={styles.list}>
                <ul>
                  {props.data
                    ? props.data.CorporateCollections2.map((d, i) => (
                        <li key={`${d}-${i}`}> {d}</li>
                      ))
                    : "loading"}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};