import { Link } from "react-router-dom";

export const GalleryNavigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            Alice Andrews Art
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>
        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li id="asdf">
              <a href='#landscapes' className='page-scroll'>
                Landscapes
              </a>
            </li>
            <li id="asdf">
              <a href='#abstractions' className='page-scroll'>
                Abstractions
              </a>
            </li>
            <li id="asdf">
              <a href='#dragonfliesandangels' className='page-scroll'>
                Dragonflies {'&'} Angels
              </a>
            </li>
            <li id="asdf">
              <a href='#pastoral' className='page-scroll'>
                Pastoral
              </a>
            </li>
            <li id="asdf">
              <a href='#dreams' className='page-scroll'>
                Dreams
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
