import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const options = {
 buttons: {
   iconColor: "#610604",
   iconPadding: "10px"
 },
 caption: {
   captionColor: "#000000",
   captionFontSize: "20px",
 },
 settings: {
   overlayColor: "#D0D9CD"
 },
 thumbnails: {
   thumbnailsAlignment: "center",
 },
};

const styles = {
  image: {
    width: '30%',
    paddingBottom: '10px',
    paddingLeft: '5px',
    paddingRight: '5px',
    cursor: 'pointer'
  }
}

export const ImageGallery = (props) => {
  return (
    <SimpleReactLightbox>
    <div id={props.data ? props.data.id : "oh doctor"} className='text-center'>
      <div className='container'>
        <div className='section-title-gallery'>
          <h2>{props.data ? props.data.title : "Loading..."}</h2>
        </div>
        <div className='row'>
        {props.data
              ? 
              <SRLWrapper options={options}>
                <div>
                  {props.data.images.map((i) => (
                    <a
                      href={i.largeImage}
                      title={i.title}
                      data-lightbox-gallery='gallery'
                    >
                      <img src={i.smallImage} style={styles.image} alt={i.title} />                      
                    </a>
                  ))}
                </div>
            </SRLWrapper>
              : 'Loading...'}
            
        </div>
      </div>
    </div>
    </SimpleReactLightbox>
  )
}