import { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { GalleryNavigation } from "../components/galleryNavigation";
import { ImageGallery } from "../components/imageGallery";
import "../App.css";

const Gallery = () => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    setPageData(JsonData);
  }, []);

  return (
    <div>
      <GalleryNavigation />
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <ImageGallery data={pageData.landscapes}/>
      <ImageGallery data={pageData.abstractions}/>
      <ImageGallery data={pageData.dragonfliesandangels}/>
      <ImageGallery data={pageData.pastoral}/>
      <ImageGallery data={pageData.dreams}/>
    </div>
  );
};

export default Gallery;