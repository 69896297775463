import { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Accolades } from "./components/accolades";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import SimpleReactLightbox from 'simple-react-lightbox'
import "./App.css";
import { Outlet } from "react-router-dom";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    setPageData(JsonData);
  }, []);

  return (
    <div>
      <SimpleReactLightbox>
        <Navigation />
        <Header data={pageData.Header} />
        <About data={pageData.About} />
        <Accolades data={pageData.Accolades} />
        <Contact data={pageData.Contact} />
        <Outlet />
      </SimpleReactLightbox>
    </div>
  );
};

export default App;
